import NavBar from "../NavBar/NavBar";
import './Header.css';
function Header(){
    return (
        <header className="App-header">
        <h1>LT</h1>
        <NavBar />
        </header>
    );
}

export default Header;